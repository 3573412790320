import headerList from "@/components/common/header-list.vue";
import paginationMixin from '@/Mixins/paginationMixin';
import { _ } from "vue-underscore";
export default {
    name: 'directoriesList',

    data() {
        return {
            directoriesFileList: [],
            filesList: [],
            directories: {},
            subdirectoriesList: [],
            path: '',
            download: '',
            currentPath: '',
            uploadedFiles: [],
            folderList: [],
            downloadList: [],
            showLoader: false,
            breadcrumbs: [],
            currentFolder: 'Home',
            path: '/',
            pathArr: []

        }
    },
    mixins: [paginationMixin],
    components: { "header-list": headerList },
    computed: {
        selectAllList() {
            if (this.folderList.length > 0) {
                return (
                    _.where(this.folderList, {
                        isSelected: true
                    }).length ===
                    this.folderList.length
                );
            }
            return false;
        },
    },
    methods: {
        checkUncheckedList(event) {
            this.folderList.forEach(element => {
                element.isSelected = event.target.checked;
            });
            this.filesList.forEach(element => {
                element.isSelected = event.target.checked;
            });
        },
        addFolder() {
            let _vm = this;
            const data = {
                ..._vm.directories,
                path: _vm.path + "/" + _vm.directories.path
            }
            this.axios
                .post("/asset-library/directories/create", data)
                .then(function () {
                    if (_vm.path === '/') {
                        _vm.getAllDirectories(_vm.path);
                    } else {
                        _vm.getSubdirectories(_vm.path);
                    }
                    document.getElementById('createFolderClose').click();

                })
                .catch(function () { });
        },
        handleFileUpload(event) {
            this.images = event.target.files;
        },
        addFile() {
            let _vm = this;

            let formData = new FormData();
            formData.append('path', _vm.path);

            for (let i = 0; i < _vm.images.length; i++) {
                formData.append('files[]', _vm.images[i]);

            }
            this.axios
                .post('/asset-library/files/store', formData)
                .then(response => {
                    if (_vm.path === '/') {
                        _vm.getAllDirectories(_vm.path);
                    } else {
                        _vm.getSubdirectories(_vm.path);
                    }
                    document.getElementById('createfile').click();
                })
                .catch(error => {
                });
        },
        formatDate(dateTimeString) {
            const dateTime = new Date(dateTimeString);
            const options = { month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZoneName: 'short' };
            return dateTime.toLocaleString('en-US', options);
        },
        getAllDirectories() {
            let _vm = this;
            this.axios
                .get("/asset-library/directories-files")
                .then(function (response) {
                    _vm.directoriesFileList = response.data.data;
                    _vm.folderList = [];
                    _vm.directoriesFileList.directories.forEach(element => {
                        _vm.folderList.push({ name: element, 'isSelected': false })
                    });
                    _vm.filesList = _vm.directoriesFileList.files;
                    _vm.filesList.forEach(element => {
                        element['isSelected'] = false;
                    });
                })
                .catch(function () { });
        },
        checkedUncheckFolder() { },
        getSubdirectories(directory, event) {
            if (event) {
                this.clicked = true;
            }
            let _vm = this;
            this.axios
                .get("/asset-library/directories-files?path=" + directory)
                .then(function (response) {
                    _vm.directoriesList = response.data.data;
                    _vm.path = directory;
                    _vm.pathArr = directory.split('/')
                    _vm.filesList = _vm.directoriesList.files;
                    _vm.folderList = [];
                    _vm.directoriesList.directories.forEach(element => {
                        _vm.folderList.push({ name: element, 'isSelected': false })
                    });
                    _vm.filesList.forEach(element => {
                        element['isSelected'] = false;
                    });

                })
                .catch(function () { });
        },
        navigate(index) {
            this.breadcrumbs.splice(index + 1);
            this.getSubdirectories(this.breadcrumbs[this.breadcrumbs.length - 1]);
        },

        deleteFolder(directory, event) {
            event.stopPropagation();

            let _msg = "Are you sure you want to Delete this Folder?"
            this.$dialog
                .confirm(_msg)
                .then(() => {
                    this.axios
                        .post('/asset-library/directories/delete', { paths: [directory] })
                        .then(response => {
                            this.directoriesList = this.directoriesList.filter(f => f !== directory);
                            _vm.getSubdirectories(_vm.path);
                        })
                        .catch(function () { });
                })
                .catch(function () { });
        },
        deleteFolderFile() {
            event.stopPropagation();
            let _msg = "Are you sure you want to Delete this Folder nad File?"
            const folderPaths = [];
            this.folderList.forEach(element => {
                if (element.isSelected == true) {
                    folderPaths.push(element.name);
                }
            });
            const filePaths = [];
            this.filesList.forEach(element => {
                if (element.isSelected == true) {
                    filePaths.push(element.path);
                }
            });
            this.$dialog
                .confirm(_msg)
                .then(() => {
                    this.axios
                        .post('/asset-library/files-folder/delete', { filePaths, folderPaths })
                        .then(response => {
                            // this.directoriesList = this.directoriesList.filter(f => f !== directory);
                            // this.fileList = this.fileList.filter(f => f !== file);
                            _vm.getAllDirectories();
                        })
                        .catch(error => {
                        });
                })
                .catch(() => {
                });
        },
        deleteFile(file, event) {
            let _msg = "Are you sure you want to Delete this File?"
            this.$dialog
                .confirm(_msg)
                .then(() => {
                    this.axios
                        .post('/asset-library/files/delete', { paths: file })
                        .then(response => {
                            this.fileList = this.fileList.filter(f => f !== file);
                            _vm.getAllDirectories();
                        })
                        .catch(function () { });
                })
                .catch(function () { });
        },
        getDownload(download, event) {

            let _vm = this;
            this.axios
                .post("/asset-library/files/download?path=" + download)
                .then(function (response) {

                })
                .catch(function () { });
        },

    },
    mounted() {
        this.getAllDirectories();
    },
}
